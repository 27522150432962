<template>
  <ul class="te-side-menu co-container-sm mx-auto list-none p-0">
    <li class="border-b border-co-gray-300">
      <a
        class="co-display-12 block p-3 text-co-gray-700 no-underline"
        href="/contact"
      >
        お問い合わせ
      </a>
    </li>
    <li class="border-b border-co-gray-300">
      <a
        class="co-display-12 block p-3 text-co-gray-700 no-underline"
        href="/company"
      >
        運営会社
      </a>
    </li>
    <li class="border-b border-co-gray-300">
      <a
        class="co-display-12 block p-3 text-co-gray-700 no-underline"
        href="/terms"
      >
        利用規約
      </a>
    </li>
    <li class="border-b border-co-gray-300">
      <a
        class="co-display-12 block p-3 text-co-gray-700 no-underline"
        href="/privacypolicy"
      >
        プライバシーポリシー
      </a>
    </li>
    <li>
      <a
        class="co-display-12 block p-3 text-co-gray-700 no-underline"
        href="/law"
        active-class="te-nav-current"
      >
        特定商取引法に関する表示
      </a>
    </li>
  </ul>
</template>

<style scoped>
.te-side-menu a {
  line-height: 1.4286;
}

@media (max-width: 767.98px) {
  .te-side-menu a {
    font-size: 0.75rem;
  }
}

.te-side-menu a.te-nav-current {
  font-weight: bold;
  background: #ccc;
}

.te-side-menu a:hover {
  color: #333;
  background: #ccc;
}
</style>
