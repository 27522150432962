<template>
  <div>
    <TheTeCommonHeader />
    <main class="bg-co-gray-200 pt-16">
      <div class="co-container-lg py-7 lg:flex lg:py-12">
        <div class="px-4 lg:w-9/12">
          <section class="co-container-sm mb-4 bg-co-gray-50 px-5 pb-5 pt-14 shadow sm:px-10 sm:py-[3.25rem] lg:mb-0">
            <slot />
          </section>
        </div>
        <div class="px-4 lg:w-3/12">
          <TeCommonSideMenu />
        </div>
      </div>
    </main>
    <TheTeCommonFooter>
      <template #top-menu>
        <ul class="te-footer-top mb-2 flex flex-wrap">
          <li class="mb-2 mr-6">
            <a
              :href="$host() + '/course'"
              class="block p-0 text-[0.875rem] text-co-gray-400 hover:text-co-gray-400"
            >コース紹介</a>
          </li><li class="mb-2 mr-6">
            <a
              :href="$host() + '/price'"
              class="block p-0 text-[0.875rem] text-co-gray-400 hover:text-co-gray-400"
            >料金プラン</a>
          </li>
          <li class="mb-2 mr-6">
            <a
              class="block p-0 text-[0.875rem] text-co-gray-400 hover:text-co-gray-400"
              href="https://support.techacademy.jp/hc/ja"
            >よくあるご質問</a>
          </li>
          <li class="mb-2 mr-6">
            <a
              :href="$host() + '/contest'"
              class="block p-0 text-[0.875rem] text-co-gray-400 hover:text-co-gray-400"
            >コンテスト</a>
          </li>
          <li class="mb-2 mr-6">
            <a
              :href="$host() + '/blog'"
              class="block p-0 text-[0.875rem] text-co-gray-400 hover:text-co-gray-400"
            >受講生インタビュー</a>
          </li>
          <li class="mb-2 mr-6">
            <a
              :href="$host() + '/jobs'"
              class="block p-0 text-[0.875rem] text-co-gray-400 hover:text-co-gray-400"
            >メンター募集</a>
          </li>
          <li class="mb-2">
            <a
              :href="$host() + '/magazine/'"
              class="block p-0 pr-3 text-[0.875rem] text-co-gray-400 hover:text-co-gray-400"
            >マガジン<i
              class="fa fa-external-link pl-2"
              aria-hidden="true"
            />
            </a>
          </li>
        </ul>
      </template>
    </TheTeCommonFooter>
  </div>
</template>

<script setup lang="ts">
import { useUser } from '~/store/user'
import TheTeCommonHeader from '~/components/Te/TeCommon/Header/TheTeCommonHeader/TheTeCommonHeader.vue'
import TheTeCommonFooter from '~/components/Te/TeCommon/Footer/TheTeCommonFooter/TheTeCommonFooter.vue'
import TeCommonSideMenu from '~/components/Te/TeCommon/TeCommonSideMenu.vue'

useHead({
  htmlAttrs: {
    'data-theme': 'techacademy',
  },
})

const user = useUser()

onMounted(async () => {
  await user.getCurrentUser()
})
</script>
